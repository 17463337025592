.tile-container {
  padding-top: 20px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.tile {
  margin: 15px 10px;
  background-color: #ffffff74;
  backdrop-filter: blur(8px);
  color: rgb(56, 142, 255);
  font-size: 28px;
  height: 200px;
  min-width: 300px;
  box-shadow: 2px 2px 12px -2px rgba(126, 126, 126, 0.53);
  border-radius: 10px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  cursor: pointer;
}

.tile svg {
  margin-bottom: -4px;
}

.tile * {
  cursor: pointer;
}
