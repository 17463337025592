.content {
  min-height: calc(100vh - 70px) !important;
  background: rgb(223, 242, 255);
  background: radial-gradient(
    circle,
    rgba(223, 242, 255, 1) 0%,
    rgba(182, 236, 255, 1) 44%,
    rgba(144, 187, 255, 1) 100%
  );
  background-attachment: fixed;
  margin-top: 70px;
  padding-top: 20px;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 95%;
  max-width: 1000px;
}

@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;1,400&display=swap");

h1:not(header h1) {
  text-align: center;
  margin-bottom: 1.7em;
  font-size: 2.3em;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
}

p {
  font-size: 110%;
  font-weight: 400;
  font-family: "Raleway";
}

.two-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.two-columns > div {
  width: calc(50% - 40px);
  margin: 0 20px;
}

@media screen and (max-width: 700px) {
  .two-columns > div {
    width: 100%;
  }
}

.one-column, .two-columns > div {
  background-color: rgba(255, 255, 255, 0.542);
  backdrop-filter: blur(8px);
  border-radius: 5px;
  padding: 20px 30px;
  margin: 10px;
  box-sizing: border-box;
}

p {
  line-height: 24px;
}