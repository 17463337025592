header {
  position: fixed;
  top: 0;
  background-color: white;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 70px;
  box-shadow: 0 2px 12px 0 rgb(56, 23, 23);
  z-index: 99;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 95%;
  max-width: 1200px;
}

@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&family=Mr+De+Haviland&display=swap");

header h1 {
  margin: 0;
  font-family: "Great Vibes", cursive;
  cursor: pointer;
}

header nav {
  height: 100%;
}

header nav ul {
  padding: 0;
  margin: 0;
  height: 100%;
}

header nav li {
  height: 100%;
  width: 120px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

header nav li:hover {
  border-bottom: 3px solid rgb(142, 22, 22);
  transition: 1s linear;
}

header nav li a {
  text-decoration: none;
  font-size: 1.2;
  text-transform: uppercase;
  color: rgb(80, 9, 9);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 440px) {
  header h1 {
    font-size: 1.5em;
  }
}

@media screen and (max-width: 650px) {
  .desktop-spacer {
    display: none;
  }
}
