footer {
  bottom: 0;
  width: 100%;
}

.bottom-img {
  bottom: 0;
  left: 0;
  width: 100vw;
  position: fixed;
  filter: brightness(1.1);
  pointer-events: none;
}
